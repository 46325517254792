import { RichText } from "prismic-reactjs"
import React from "react"
import { graphql } from 'gatsby'
import SEO from "../components/SEO"
import IllustrationBoarCut from "../images/illustrationBoarCut"
import PageNotFoundLayout from "../layouts/PageNotFoundLayout"
import style from "./404.module.scss"

const PageNotFound = props => {
  const { heading, lead } = props.data.prismic.allPage_not_founds.edges[0].node

  return (
    <PageNotFoundLayout>
      <SEO title="404: Not found" />
      <div className={style.illustrationContainer}>
        <IllustrationBoarCut />
      </div>
      <div className={style.textContainer}>
        <h1
          className={style.heading}
          dangerouslySetInnerHTML={{ __html: RichText.asText(heading) }}
        />
        <div className={style.leadContainer}>
          <RichText render={lead} />
        </div>
      </div>
    </PageNotFoundLayout>
  )
}

export const query = graphql`
  {
    prismic {
      allPage_not_founds {
        edges {
          node {
            heading
            lead
          }
        }
      }
    }
  }
`

export default PageNotFound
