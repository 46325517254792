import React from "react"
import PropTypes from "prop-types"
import Header from "../components/Header"
import style from "./PageNotFoundLayout.module.scss"

const PageNotFoundLayout = props => {
  const { children } = props

  return (
    <>
      <Header />

      <main className={style.main}>{children}</main>
    </>
  )
}

PageNotFoundLayout.propTypes = {
  children: PropTypes.node,
}

export default PageNotFoundLayout
